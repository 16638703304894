import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "Tentang",
    wpTtl: "Kertas putih",
    allocTtl: "Peruntukan Token",
    deckTtl: "Dek Padang",
    presaleTtl: "Prajualan",
    rmTtl: "Pelan hala tuju",
    auditTtl: "Audit",
    projectTtl: "Projek",
    quickLinksTtl: "Quick links",
    privPolicy: "Dasar Privasi",
    termsAndConds: "Terma dan syarat",
    amlPolicy: "Dasar Pencegahan Pengubahan Wang Haram",
    sitemap: "Peta laman",
    salePolicy: `Terma dan Syarat Jualan ${config.tokenSymbol}`,
    descText1:
      "Selamatkan alam daripada anarki dalam RPG Tindakan mudah alih yang mengasyikkan ini! Lawan musuh dari dunia bawah tanah purba menggunakan mantra sihir dan senjata besi yang sengit! Ikuti sosial kami untuk kekal dikemas kini!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "PUSINGAN PRA JUALAN AKHIR!",
    presaleEnded: "JUALAN AWAL BERAKHIR!",
    stayTuned: "Sila teruskan perhatian untuk arahan tuntutan token.",
    thankyouForParticipating:
      "TERIMA KASIH KERANA MENYERTAI PRAPENJUALAN hbox!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "Nantikan pengumuman IDO. Harga pelancaran:",
    soldTtl: "TERJUAL",
    raisedTtl: "DIBANGKITKAN",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "Akses eksklusif ke MVP closed beta",
    benefit2: "Akses ke dev diary",
    benefit3: "Senjata dalam permainan \n Legendaris Terhad",
    benefit4: "Pengesahan automatik untuk jualan NFT yang akan datang",
    benefit5: "Kemasukan ke peraduan phygital",
    benefit6: "Dan banyak lagi!",
    whybuyToken1:
      "MVP boleh dimainkan dan versi beta akan dilancarkan pada bulan Mei",
    whybuyToken2: "4 juta muat turun gabungan di Android dan iOS",
    whybuyToken3: "6 juta dalam pendapatan untuk versi MVP",
    whybuyToken4:
      "rancangan menyeluruh selama 2 tahun untuk pembangunan permainan dan penerimaan pengguna",
    whybuyToken5: "Pasukan terdiri daripada veteran pembangunan permainan",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "Lebih 4J muat turun di App Store dan Playstore",
    wygTitle2: "6J+ USD hasil daripada pembelian dalam apl",
    wygTitle3: "4.5 daripada 5 penilaian bintang",
    wygTitle4: "250+ jam permainan kempen",
    wygTitle5: "Berbilang mod permainan tak segerak tersedia",
    wygTitle6: "web3 MVP dilancarkan pada September 2024",
    wygTitle7: "Projek dikendalikan oleh veteran pembangunan permainan",
    wygTitle8: "Main silang didayakan untuk mudah alih dan PC",
    wygTitle9: "Peta Multiplayer PvP yang sangat memikat untuk 16 pemain",
    wygTitle10:
      "Nikmati pengalaman permainan pada 60fps dalam resolusi 4k (PC)",
    modeT1: "Perburuan Monster",
    modeT2: "Benteng Boss",
    modeT3: "PvP",
    modeT4: "Dungeon Harian",
    modeT5: "Dungeon Acara",
    modeT6: "Kempen",
    modeInfo:
      "MVP Swords of Blood mempunyai beberapa mod permainan! Dapatkan akses eksklusif ke beta tertutup (pelancaran pada bulan Mei) apabila anda menyertai",
    aboutDesc1: `Mempunyai pertempuran yang mencolok, grafik cemerlang dan pelbagai mod permainan, ${config.appName} ialah RPG hack-and-slash F2P berkualiti AAA yang pertama dalam pelancaran blockchain di Ape Chain.`,
    aboutDesc2:
      "Keganasan, pengkhianatan, dan kejahatan licik telah membawa kejatuhan kejam dunia Ezura. Pengkhianat, makhluk jahat yang sangat besar, telah memusnahkan pelindung tegar alam dan selama 1,000 tahun, kegelapan telah memerintah.",
    aboutDesc3:
      "Ia adalah masa terdesak, putus asa, dan kematian, kerana tentera Pengkhianat meliputi negeri itu.",
    aboutDesc4:
      "Tetapi dari runtuhan, kemungkinan keselamatan timbul; perintah terakhir pahlawan yang dahulunya perkasa telah dibangkitkan oleh dewi purba yang dikenali sebagai Sentinel. Bersama-sama, anda akan cuba membawa percikan harapan ke tanah yang terbiar.",
    aboutDesc5:
      "Jadilah perayap penjara bawah tanah yang berani, ahli sihir pertempuran memburu hadiah, atau pahlawan yang kuat, menggiling barang rampasan dan peralatan yang jarang ditemui. Buat peralatan epik, bengkokkan keajaiban alam semula jadi mengikut kehendak anda, dan balas dendam kepada saudara-saudara anda yang terkorban dalam usaha epik keperkasaan dan membalas dendam.",
    contactUsDesc: `Jadi duta ${config.appName} dan dapatkan token permainan, emas dan permata.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "Seperti yang dilihat pada",
    facTtl1: "Temui dunia Ezura yang indah",
    facTtl2: "Kuasai pelbagai jenis senjata dan elemen",
    facTtl3: "Bina kombinasi serangan yang dahsyat",
    facTtl4: "Buat peralatan dan senjata yang unik dan berkuasa",
    facTtl5: "Gunakan permainan hack n slash untuk membunuh lawan anda",
    facTtl6: "Compete with other players in fast-paced, brutal PVP",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamBio5: "A decade of experience in UI/UX design",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "Penyokong",
    launchPartnTtl: "Rakan Kongsi Lancar",
    techPartnTtl: "Rakan Kongsi Teknologi",
    comPartnTtl: "Rakan Kongsi Komuniti",
    presTtl: "Cara membeli",
    presStep1: "LANGKAH 1",
    presStep2: "LANGKAH 2",
    presStep3: "LANGKAH 3",
    presStep1Desc:
      "Mula-mula, pastikan anda memasang dompet MetaMask pada penyemak imbas anda, atau gunakan salah satu dompet untuk menyambung ke salah satu dompet yang disokong (kami mengesyorkan Trust Wallet).<br/>Membeli daripada penyemak imbas desktop akan memberikan anda pengalaman pembelian yang lebih lancar. Untuk ini kami mengesyorkan Metamask.<br/>Jika anda membeli melalui telefon mudah alih, kami mengesyorkan menggunakan Trust Wallet dan menyambung melalui penyemak imbas terbina dalam (hanya salin https://swordsofblood.com ke penyemak imbas Trust Wallet).",
    presStep2Desc:
      "Sebaik sahaja anda mempunyai pembekal dompet yang ingin anda gunakan, klik Sambungkan Wallet dan pilih pilihan yang sesuai. Dalam kes aplikasi dompet mudah alih, anda perlu memilih Wallet Connect.",
    presStep3Desc: `Anda boleh membeli ${config.tokenSymbol} dengan ERC20 ETH, USDT, USDC dan DAI. Selepas semua peringkat prajualan selesai, anda akan dapat menuntut ${config.tokenSymbol} anda melalui halaman ini. Sila tunggu pengumuman kami.. `,

    date: {
      Days: "HARI",
      Hours: "JAM",
      Minutes: "MINIT",
      Seconds: "SEKALI",
    },
    timerLabel: "Prajualan 3 berakhir pada :",
    investorTtl: "Pelabur Pusingan Benih",
  };
}
