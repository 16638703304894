import { useContext, useState, useEffect, useRef, Suspense, lazy } from "react";
import { NavLink } from "react-router-dom";
import Web3Provider from "../../context/web3provider";
import Localization from "../../context/localization";
import Video from "./bannerVideo";

import {
  SetGlobalProvider,
  getPresaleContract,
  setNetwork,
  DisconnectProvider,
  GetAccount,
  GetBalance,
} from "../../functions/onchain";
import {
  retrievePresaleInfo,
  retrieveAdditionalPresaleInfo,
} from "../../functions/common";
import { config } from "../../functions/config";
import {
  importAllImages,
  SetStorageData,
  GetStorageData,
  getCurrentTimestamp,
} from "../../functions/common";
import ModalBuy from "../modal-buy/modal-buy";
import styles from "./banner.module.css";
import Countdown from "../countdown/Countdown";
import "./custom.css";
import ModalConnectWallet from "../modal-connect-wallet/modal-connect-wallet";

import swordfairy from "../../images/home/swordfairy.png";

// const Video = lazy(() => import("./bannerVideo"));

export default function Banner() {
  const [refresh, setRefresh] = useState(0);
  const { strings } = useContext(Localization);
  const web3provider = useContext(Web3Provider);
  const [inLoading, setInLoading] = useState(true);
  const [presaleData, setPresaleData] = useState(null);
  const [curStep, setCurStep] = useState(0);
  // const [loadingInfoText, setLoadingInfoText] = useState(null);
  // const [showConnectModal, setShowConnectModal] = useState(false);
  // const [showBuyModal, setShowBuyModal] = useState(false);
  // const [purchaseToken, setPurchaseToken] = useState(4);
  // const [hideConnectButton, setHideConnectButton] = useState(false);
  const curStepRef = useRef(0);
  const progressLine = useRef(null);
  const inFetch = useRef(false);
  const images = importAllImages();

  const get_percent = (
    totalTokensSold,
    stageTotalTokensTarget,
    totalUSDraised,
    stageTotalUSDTarget
  ) => {
    const percent_tokens = Math.round(
      (totalTokensSold / stageTotalTokensTarget) * 100
    );
    const percent_raised = Math.round(
      (totalUSDraised / stageTotalUSDTarget) * 100
    );
    return Math.round((percent_raised + percent_tokens) / 2);
  };

  const getPrepresaleData = async (mode = 0) => {
    if (mode > 0 && !web3provider.current.status) {
      Disconnect();
      return false;
    }

    try {
      setInLoading(true);

      let info = {},
        getSC = true;

      if (presaleData) info = presaleData;
      else {
        const storedPresaleData = GetStorageData("storedPresaleData");
        if (storedPresaleData) {
          const decData = JSON.parse(storedPresaleData);
          if (!decData.ldt || getCurrentTimestamp() - decData.ldt < 3600) {
            Object.assign(info, decData);
            getSC = false;
          }
        }
      }
      if (mode > 0) {
        info.wallet = await GetAccount(web3provider.current.instance);
        if (!info.wallet) {
          Disconnect();
          return false;
        }
      }

      if (mode === 0) {
        web3provider.current.network = setNetwork(
          process.env.NODE_ENV === "development" ? "BSC" : "ETHEREUM"
        );
        await SetGlobalProvider(2, web3provider);
      }

      let contract = getPresaleContract(web3provider.current.instance);
      if ((mode === 0 || mode === 2) && getSC) {
        inFetch.current = true;
        Object.assign(info, await retrievePresaleInfo(contract));
        SetStorageData(
          "storedPresaleData",
          JSON.stringify(Object.assign(info, { ldt: getCurrentTimestamp() }))
        );
        inFetch.current = false;
      }

      if (mode >= 1)
        Object.assign(
          info,
          await retrieveAdditionalPresaleInfo(contract, info)
        );

      // check the total token sold if it's heigh leave it as it is or add the static price
      if (info.totalTokensSold + 27500000 <= 32500000) {
        info.totalTokensSold += 27500000;
        info.totalUSDraised += 1100000;
      }
      info.stageTotalTokensTarget = 32500000;
      info.stageTotalUSDTarget = 1370000;

      // get the precent from the total usd and token
      info.stagePercentsCompleted = get_percent(
        info.totalTokensSold,
        info.stageTotalTokensTarget,
        info.totalUSDraised,
        info.stageTotalUSDTarget
      );
      setPresaleData(info);

      if (mode > 0) setCurStep(1);
      setInLoading(false);
    } catch (err) {
      setInLoading(false);
    }
  };

  const Disconnect = () => {
    DisconnectProvider(web3provider);
    setCurStep(0);
    setInLoading(false);
  };

  useEffect(() => {
    curStepRef.current = curStep;
  }, [curStep]);

  useEffect(() => {
    if (window && window.ethereum) {
      window.ethereum.on("chainChanged", (chainIdHex) => {
        if (chainIdHex !== config[web3provider.current.network].chainIdHex)
          Disconnect();
      });

      window.ethereum.on("accountsChanged", async () => {
        if (
          !web3provider.current.status ||
          !(await GetAccount(web3provider.current.instance))
        )
          Disconnect();
        else if (curStepRef.current === 1) await getPrepresaleData(2);
      });
    }

    getPrepresaleData(0);

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      web3provider.current.status = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <div className={["mainBanner", "timer", styles.banner].join(" ")}>
        <div
          style={{ justifyContent: "flex-start" }}
          className={styles.heroSection}
        >
          <Suspense
            fallback={
              <img
                src={images["home/banner/hero3.png"]}
                alt="Play-to-earn is out. Earn real rewards with Swords of Blood's play-to-own and play-to-win model where the gaming experience is the priority."
                className={["image", styles.image].join(" ")}
              />
            }
          >
            <Video muted />
          </Suspense>

          <div className={styles.cardOverlay}>
            <div className={styles.cardContent}>
              <div className="s_card">
                <img
                  className="top_eye"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sword_top.png"
                  alt="top"
                />
                <img
                  className="bottom_eye"
                  src="https://tuk-cdn.s3.amazonaws.com/can-uploader/sword_bottom.png"
                  alt="bottom"
                />
                <ul className="s_card-list">
                  <li>Beta exclusive player model NFT</li>
                  <li>NFT armor set with Beta-only art</li>
                  <li>The Sword of Blood NFT Beta-only model</li>
                  <li>
                    Entry into the sweepstakes to win a trip for 2 to meet the
                    dev team in California, 5-night stay at Hotel Petaluma,
                    airfare
                  </li>
                </ul>
                <button
                  onClick={() =>
                    window.open("https://forms.gle/yDDSmeDRhqg1QBAB9", "_blank")
                  }
                  className="s_sign-up-button"
                >
                  Sign up
                </button>
              </div>
              {/* <div className={styles.victoryImage}>
                <div>
                  <div className={styles.prizeTitle}>PRIZES</div>
                  <div>
                    <div className={styles.bulletpoint}>
                      <img src={images["svg/bullet-point.svg"]} alt="right" />
                      <div className={styles.bulletText}>
                        30k USD in token drops
                      </div>
                    </div>

                    <div style={{ marginTop: "1.8rem" }} />
                    <div className={styles.bulletpoint}>
                      <img src={images["svg/bullet-point.svg"]} alt="right" />
                      <div className={styles.bulletText}>
                        3 beta only items from the XP store
                      </div>
                    </div>

                    <div style={{ marginTop: "1.8rem" }} />
                    <div className={styles.bulletpoint}>
                      <img src={images["svg/bullet-point.svg"]} alt="right" />
                      <div className={styles.bulletText}>
                        Exclusive VIP amas
                      </div>
                    </div>

                    <div style={{ marginTop: "1.8rem" }} />
                    <div className={styles.bulletpoint}>
                      <img src={images["svg/bullet-point.svg"]} alt="right" />
                      <div className={styles.bulletText}>
                        Instant access to future PVP beta
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className={styles.betaSignUpButton}
                onClick={() =>
                  window.open("https://forms.gle/yDDSmeDRhqg1QBAB9", "_blank")
                }
              >
                BETA SIGN UP
              </button> */}
            </div>
          </div>
        </div>
        {/* <div
          className={[
            styles.connectWalletContainer,
            curStep === 1 ? styles.step1 : "",
          ].join(" ")}
        > */}
        {/* <div className={styles.presaleWrap}></div> */}
        {/* <div className={styles.hboxContainer}>
            <img
              src={images["home/presale.webp"]}
              alt="Swords of Blood"
              className={styles.presaleImage}
            />
            <p className={styles.hbox}>GET YOUR hbox FROM</p>
          </div>
          <div className={styles.MEXC}>
            <a href="https://www.mexc.com/exchange/hbox_USDT" target="_blank"> */}
        {/* <img
                src={images["MEXC.png"]}
                alt="MEXC"
                className={styles.mexcimg}
              /> */}
        {/* get your tokens */}
        {/* <p className="get_you"> here instead of mexc</p>
              
            </a>
          </div>
        </div> */}
      </div>
      <style>
        {`
      .s_card {
  border-radius: 10px;
  padding: 45px;
  // max-width: 400px;
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 100;
  // height:100%;
}

/* Header styles */
.s_card-header {
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
  /* background-color: #2a292f; */
  /* border: 2px solid #a6825b; */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
}

/* List styles */
.s_card-list {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
}

.s_card-list li {
  margin: 25px 0;
  padding-left: 20px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  position: relative;
}

.s_card-list li::before {
  content: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/swords-dino.png");
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%) scale(0.3);
}

/* Button styles */
.s_sign-up-button {
  background-image: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/swords_btn.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  color: #c9ab84;
  /* border: 2px solid #C9AB84; */
  padding: 0px 20px;
  /* border-radius: 5px; */
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  transition: 0.3s;
  width: 248px;
  height: 35px;
  border: none;
  z-index: 10;
  position: relative;
}

.s_sign-up-button:hover {
  opacity: 0.7;
}
.top_eye {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.bottom_eye {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
  @media (max-width:768px){
       .s_card {
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 15px;
  padding-bottom: 25px;
}
.s_card-list li {
  margin: 25px 0;
  padding-left: 20px;
  font-size: 14px;
}

.s_card-list li::before {
  content: url("https://tuk-cdn.s3.amazonaws.com/can-uploader/swords-dino.png");
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%) scale(0.2);
} 
  .s_sign-up-button {
  font-size: 14px;
  width: 200px;
  height: 30px;
} 
        }
  @media (min-width:768px) and (max-width: 1024px) {
  .s_card-list li {
    margin: 20px 0!important;
  }

}
      `}
      </style>
    </>
  );
}
