import { config } from "../functions/config";

export default function Strings() {
  return {
    tokenAddress: "TOKEN ADDRESS",
    logoTitle: "Logo",
    aboutTtl: "정보",
    wpTtl: "백서",
    allocTtl: "토큰 할당",
    deckTtl: "피치 덱",
    presaleTtl: "프리세일",
    rmTtl: "로드맵",
    auditTtl: "감사",
    projectTtl: "프로젝트",
    quickLinksTtl: "Quick links",
    privPolicy: "개인정보 처리방침",
    termsAndConds: "약관 및 조건",
    amlPolicy: "자금세탁 방지 정책",
    sitemap: "사이트맵",
    salePolicy: `${config.tokenSymbol} 판매 이용약관`,
    descText1:
      "이 몰입형 모바일 액션 RPG에서 무정부 상태로부터 왕국을 구하세요! 마법 주문과 강력한 철제 무기를 사용해 고대 지하세계의 적들과 싸우세요! 소셜 미디어를 팔로우하여 최신 소식을 받아보세요!",
    descText2:
      "Copyright © 2022-2024 Hitbox Holdings SVG LLC, a St Vincent Corporation",
    descText3:
      "Suite 305 Griffith Corporate Centre, Kingstown, St Vincent, and the Grenadines.",
    quickLinks: "Quick links",
    // presaleStage: 'Presale stage',
    presaleStage: "최종 사전 판매 라운드!",
    presaleEnded: "프리세일 종료!",
    stayTuned: "토큰 신청 지침에 대해 계속 주시해 주세요. ",
    thankyouForParticipating: "hbox PRESALE에 참여해 주셔서 감사합니다!",
    // buyBeforeTheEnd: "Hurry before the end of presale!",
    buyBeforeTheEnd: "Please wait for announcements",
    buyBefore: "IDO 발표를 계속 지켜봐 주십시오. 출시 가격:",
    soldTtl: "Sold",
    raisedTtl: "Raised",
    connectWallet: "Connect Wallet",
    minimumPurchWarn: `Purchase amount must be not less than {VALUE1} ${config.tokenSymbol} and no more than {VALUE2} ${config.tokenSymbol}.`,
    minimumPurchWarn2: `Purchase amount must be no more than {VALUE1} ${config.tokenSymbol}.`,
    presaleInactive: "Presale is inactive.",
    insufFunds: "You have not enough money to make a transaction.",
    impossToTx: "It is impossible to make a transaction.",
    invalidTime: "Invalid time.",
    stayAndWait:
      "Please stay on the website and wait for the transaction confirmation",
    goesWrong: "You canceled the operation or something goes wrong.",
    thankYou: "Thank you for your purchase!",
    tokPayFailed: "Token payment failed",
    claimNoFunds: "You have no tokens available for withdrawal.",
    statClaimed: `Your ${config.tokenSymbol} tokens will be sent to your address soon.`,
    connectNote:
      "*Please note that, this action can not be undone once the wallet is authorize.",
    connectTheWallet: "Connect the wallet",
    youOwn: "You own",
    buyWith: "Buy with",
    claimTokens: "Claim",
    buyTokenWith: `Buy ${config.tokenSymbol} with`,
    minimumTtl: "Minimum",
    buyDesc: `Enter the amount of ${config.tokenSymbol} you wish to purchase, then click "Buy"`,
    buyTtl: "Buy",
    payTtl: "Pay",
    benefit1: "MVP 클로즈드 베타에 대한 독점적인 액세스",
    benefit2: "개발 일지에 대한 액세스",
    benefit3: "한정판 전설적인 무기",
    benefit4: "다가오는 NFT 판매에 대한 자동화 화이트리스트 등록",
    benefit5: "피지탈 기부 행사 참여 기회",
    benefit6: "그 외 다양한 혜택!",
    whybuyToken1: "MVP는 플레이 가능하며 베타 버전은 5월에 출시 예정입니다",
    whybuyToken2: "안드로이드 및 iOS에서 총 400 만 다운로드",
    whybuyToken3: "MVP 버전으로 600 만 달러의 수익 창출",
    whybuyToken4: "게임 개발 확장 및 사용자 확보를 위한 2년간의 포괄적인 계획",
    whybuyToken5: "팀은 게임 개발 베테랑으로 구성되어 있습니다",
    approveMessage: `Payment with stablecoins will require confirmation of two transactions: first to approve spending and second to ${config.tokenSymbol} purchase. Stay on the website until the all transactions are confirmed. Only ERC-20 tokens supported.`,
    wygTitle1: "역동적인 핵 앤 슬래시 RPG",
    wygTitle2: "몰입도가 높고 아름답게 렌더링된 게임플레이",
    wygTitle3: "PvE 및 PvP 모드",
    wygTitle4: "250시간 이상의 캠페인 게임 플레이 타임",
    wygTitle5: "여러 비동기 게임 모드 사용 가능",
    wygTitle6: "Web3 MVP는 2024년 9월에 출시 예정입니다",
    wygTitle7: "프로젝트는 게임 개발 베테랑들이 운영합니다",
    wygTitle8: "모바일 및 PC에서 크로스 플레이 가능",
    wygTitle9: "16명 플레이어 멀티플레이어 PvP 맵으로 매우 몰입감 있음",
    wygTitle10: "PC에서 60fps의 4K 게임 경험 즐기기",
    modeT1: "몬스터 헌트",
    modeT2: "보스 요새",
    modeT3: "PvP",
    modeT4: "일일 던전",
    modeT5: "이벤트 던전",
    modeT6: "캠페인",
    modeInfo:
      "Swords of Blood의 MVP는 여러 게임 모드를 제공합니다! 현재 진행 중인 이벤트에 참여하여 5월에 시작되는 클로즈드 베타에 독점 액세스를 얻으세요",
    aboutDesc1: `화려한 전투, 뛰어난 그래픽, 다양한 게임 모드를 자랑하는 소드 오브 블러드는 텔로스에서 출시되는 블록체인 최초의 AAA급 빠른 속도의 F2P 핵 앤 슬래시 RPG입니다.`,
    aboutDesc2:
      "폭력과 배신, 교활한 악으로 인해 에주라 세계는 잔인한 몰락을 맞이했습니다. 거대한 악의 존재인 배신자는 왕국의 충실한 수호자들을 몰살시켰고, 1,000년 동안 어둠이 지배했습니다.",
    aboutDesc3:
      "배신자의 군대가 땅을 뒤덮은 지금은 절망과 절망, 죽음의 시대입니다.",
    aboutDesc4:
      "그러나 폐허에서 구원의 가능성이 생겨납니다. 한때 강력했던 전사의 마지막 조직이 센티널로 알려진 고대 여신에 의해 부활했습니다. 여러분은 함께 황폐한 땅에 희망의 불꽃을 불러일으키기 위해 노력해야 합니다.",
    aboutDesc5:
      "두려움 없는 던전 크롤러, 현상금 사냥 배틀메이지 또는 강력한 전사가 되어 전리품과 희귀 장비를 찾아다니세요. 장대한 장비를 제작하고, 자연의 마법을 마음대로 구부리고, 쓰러진 형제의 복수를 위해 힘과 복수를 위한 장대한 여정을 떠나세요.",
    contactUsDesc: `소드 오브 블러드 홍보대사가 되어 게임 토큰, 골드, 보석을 획득하세요.`,
    contactUs: "Contact Us",
    asSeenOnTtl: "에서 볼 수 있듯이",
    facTtl1: "아름다운 에즈라의 세계를 발견하세요",
    facTtl2: "다양한 무기와 원소를 마스터하세요",
    facTtl3: "파괴적인 공격 조합을 구축하세요",
    facTtl4: "독특하고 강력한 장비와 무기를 제작하세요",
    facTtl5: "핵 앤 슬래시 게임 플레이를 사용하여 상대를 처치하십시오.",
    facTtl6: "빠르게 진행되는 잔인한 PvP에서 다른 플레이어와 경쟁하십시오.",
    teamBio1:
      "A 36-year veteran of the video game industry who has worked on game titles, such as The Sims, Sims City, Ghost Recon, Rainbow Six, and more), on many levels - from programming to CEO.",
    teamBio2:
      "Worked extensively on the Sony PlayStation and Xbox marketing accounts as well as some of the most iconic game titles including Halo, Call of Duty, Assassin’s Creed and the Tom Clancy franchises.",
    teamBio3:
      "Over 10 years of experience as a gaming executive focused on video games publishing and sales of PC/consoles and mobile Free-To-Play and blockchain-enabled games. Cofounder of Artifex Mundi S.A.",
    teamBio4:
      "Over ten years of experience in community building, marketing, public and client relations, content writing andcreation, and social media management including projects in web3, gamefi, and defi.",
    teamBio5: "A decade of experience in UI/UX design",
    teamBio6:
      "Former Director of Narrative Design for Cyberpunk and The Witcher 3",
    teamBio7:
      "Star Judge and world champion bladesmith on the History Channel’s hit show, Forged in Fire",
    teamBio8:
      "Lead writer for the upcoming megahit: Gord Author and Hollywood Screenplay Writer",
    teamName1: "James Seaman",
    teamTtl1: "CEO",
    teamName2: "Jeremy Brown",
    teamTtl2: "COO",
    teamName3: "Mariusz Szynalik",
    teamTtl3: "Project Director for HitBox Games",
    teamName4: "Yupeng Qin",
    teamTtl4: "Project Director for Asian Dev Team",
    teamName5: "Jakub Szamalek",
    teamTtl5: "Narrative Director",
    teamName6: "John Moyer",
    teamTtl6: "Bass Guitarist of the band Disturbed",
    teamName7: "Vee Lozano",
    teamTtl7: "Business Development Lead",
    teamName8: "Jason Hung",
    teamTtl8: "Advisor",
    teamName9: "Charlie Hu",
    teamTtl9: "Advisor",
    teamName10: "Adam Vine",
    teamTtl10: "Narrative Design Director",
    teamName11: "Ben Abbott",
    teamTtl11: "Weapons Creator and Consultant ",
    teamName12: "Kamran Sadikhov",
    teamTtl12: "UI/UX Designer",
    teamTtl: "Team",
    partnersTtl: "Partners",
    backersTtl: "후원자",
    launchPartnTtl: "출시 파트너",
    techPartnTtl: "기술 파트너",
    comPartnTtl: "커뮤니티 파트너",
    presTtl: "구매 방법",
    presStep1: "1단계",
    presStep2: "2단계",
    presStep3: "3단계",
    presStep1Desc:
      "먼저 브라우저에 메타마스크 지갑이 설치되어 있는지 확인하거나 지원되는 지갑 중 하나를 사용하여 연결하세요(트러스트 지갑을 권장합니다).<br/>데스크톱 브라우저에서 구매하면 더 원활한 구매 경험을 할 수 있습니다. 이를 위해 메타마스크를 권장합니다.<br/>휴대폰에서 구매하는 경우, 트러스트 월렛을 사용하고 내장 브라우저를 통해 연결하는 것이 좋습니다(https://swordsofblood.com 을 트러스트 월렛 브라우저에 복사하기만 하면 됩니다).",
    presStep2Desc:
      "사용하려는 지갑 서비스 제공업체를 찾았으면 지갑 연결을 클릭하고 적절한 옵션을 선택합니다. 모바일 지갑 앱의 경우 지갑 연결을 선택해야 합니다.",
    presStep3Desc: `ERC20 ETH, USDT, USDC, DAI 로 ${config.tokenSymbol}을 구매할 수 있습니다. 모든 사전 판매 단계가 끝나면 이 페이지를 통해 ${config.tokenSymbol}을 수령할 수 있습니다. 공지사항을 기다려주시기 바랍니다.`,

    date: {
      Days: "일수",
      Hours: "시간",
      Minutes: "분",
      Seconds: "초",
    },
    timerLabel: "사전 판매 3은 다음에서 종료됩니다.",
    investorTtl: "시드 라운드 투자자",
  };
}
